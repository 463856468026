import React from "react";
import "../../asset/css/Home.css";
// import Navbar from '../Navbar/Navbar'; //
const Home = () => {
  return (
    <div className="home-container">
      {/* Navbar */}
      {/* <Navbar /> */}

      {/* Header Section */}
      <header className="header-section">
        <h2>Welcome to Hungreli</h2>
        <p>Your one-stop solution for delicious food delivery!</p>
      </header>

      {/* Flexbox Section */}
      <section className="flexbox-section">
        <div className="flexbox-container">
          <div className="flexbox-item item1">Discover Restaurants</div>
          <div className="flexbox-item item2">Order Your Favorites</div>
        </div>
      </section>

      {/* Main Section */}
      <main className="main-section">
        <h2>Why Choose Us?</h2>
        <p>
          At Hungreli, we connect you to the best local eateries. Fast delivery, amazing offers, and a seamless experience await you!
        </p>
      </main>

      {/* Footer Section */}
      {/* <footer className="footer-section">
        <p>© 2024 Hungreli. All rights reserved.</p>
      </footer> */}
    </div>
  );
};

export default Home;
