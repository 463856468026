import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import AdminPage from "./components/Admin/AdminPage";
import AdminDashboard from "./components/Admin/AdminDashboard";
import AdminProfile from "./components/Admin/AdminProfile";
import RestaurantPartnerPage from "./components/Restaurants/RestaurantPartnerPage";
import RestaurantFood from "./components/Restaurants/restaurantFood";
import RestaurantDash from "./components/Restaurants/RestaurantDash";
import RestaurantListPage from "./components/Restaurants/RestaurantListpage";
import RegFeePay from "./components/Restaurants/RegFeePay";
import DeliveryBoyPage from "./components/DeliveryBoy/DeliveryBoyPage";
import Home from "./components/Homepage/Home";
import AdminNavbar from "./components/Navbar/AdminNavbar";
import DeliveryDash from "./components/DeliveryBoy/DeliveryDash";
// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./asset/css/Navbar.css";
import "./asset/css/Home.css";
import "./asset/css/Admindashboard.css";
import "./asset/css/restaurantFood.css";
import "./asset/css/AdminPage.css";
import "./asset/css/RestaurantDash.css";
import "./asset/css/RestaurantListPage.css";
import "./asset/css/DeliveryBoyPage.css";


const AppContent = () => {
  const location = useLocation();

  // Define routes for Navbar and AdminNavbar visibility
  const navbarRoutes = ["/", "/admin", "/restaurant-partner", "/delivery-boy"];
  const adminNavbarRoutes = [
    "/AdminDashboard",
    "/adminProfile",
    "/restaurantFood",
    "/RestaurantDash",
    "/DeliveryDash",
  ];

  // Determine Navbar type
  const showNavbar = navbarRoutes.includes(location.pathname);
  const showAdminNavbar = adminNavbarRoutes.includes(location.pathname);

  return (
    <div className="app">
      {/* Conditional Navbar Rendering */}
      <header className="header">
        {showNavbar && <Navbar />}
        {showAdminNavbar && <AdminNavbar />}
      </header>

      {/* Main Content */}
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/admindashboard" element={<AdminDashboard />} />
          <Route path="/adminProfile" element={<AdminProfile />} />
          <Route path="/restaurant-partner" element={<RestaurantPartnerPage />} />
          <Route path="/RegFeePay" element={<RegFeePay />} />
          <Route path="/restaurantFood" element={<RestaurantFood />} />
          <Route path="/RestaurantDash" element={<RestaurantDash />} />
          <Route path="/delivery-boy" element={<DeliveryBoyPage />} />
          <Route path="/RestaurantListpage" element={<RestaurantListPage />} />
          <Route path="/DeliveryDash" element={<DeliveryDash />} />
        </Routes>
      </main>

      {/* Footer */}
      <footer className="footer text-center mt-5">
        <p>© 2024 Hungreli. All rights reserved.</p>
      </footer>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;