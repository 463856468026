import React from 'react';

// import '../assets/css/RegFeePay.css'; // Optional: Create and link a CSS file for styling

const RegFeePay = ({ onPaymentSuccess }) => {
  const handlePayment = (method) => {
    // Simulated payment handling logic
    alert(`Payment initiated using ${method}.`);
    // Mock payment success flow
    setTimeout(() => {
      alert('Payment successful! Your account has been activated.');
      onPaymentSuccess(); // Callback to handle post-payment navigation
    }, 2000);
  };

  return (
    <div className="reg-fee-pay">
      <h2>Pay Registration Fee</h2>
      <p>
        Thank you for registering! To activate your account, please pay the
        registration fee of <strong>₹300</strong>.
      </p>

      <div className="payment-methods">
        <h3>Choose a Payment Method:</h3>
        <button onClick={() => handlePayment('Bank Transfer')} className="payment-button">
          Bank Transfer
        </button>
        <button onClick={() => handlePayment('UPI')} className="payment-button">
          UPI (Google Pay, PhonePe, etc.)
        </button>
        <button onClick={() => handlePayment('Credit/Debit Card')} className="payment-button">
          Credit/Debit Card
        </button>
        <button onClick={() => handlePayment('Net Banking')} className="payment-button">
          Net Banking
        </button>
        <button onClick={() => handlePayment('Wallet')} className="payment-button">
          Wallet (Paytm, Mobikwik, etc.)
        </button>
      </div>
    </div>
  );
};

export default RegFeePay;
