import React, { useState } from "react";

const AdminProfile = () => {
  // Example saved details
  const [adminDetails, setAdminDetails] = useState({
    profilePic: "",
    name: "Admin Name",
    email: "admin@example.com",
    phone: "1234567890",
    address: "123 Admin Street, Admin City",
  });

  const [isEditing, setIsEditing] = useState(false);
  const [formDetails, setFormDetails] = useState(adminDetails);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDetails({ ...formDetails, [name]: value });
  };

  const handleSave = () => {
    setAdminDetails(formDetails);
    setIsEditing(false);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setFormDetails({ ...formDetails, profilePic: reader.result });
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="admin-profile card p-4">
      <h2>Admin Profile</h2>
      <div className="profile-picture-section text-center mb-4">
        <label htmlFor="profilePicUpload" className="d-block">
          <img
            src={formDetails.profilePic || "https://via.placeholder.com/150"}
            alt="Profile"
            className="rounded-circle"
            style={{ width: "150px", height: "150px", objectFit: "cover" }}
          />
        </label>
        {isEditing && (
          <input
            type="file"
            id="profilePicUpload"
            className="form-control mt-2"
            onChange={handleImageUpload}
            accept="image/*"
          />
        )}
      </div>

      <div className="profile-details">
        {isEditing ? (
          <>
            <div className="mb-3">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={formDetails.name}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formDetails.email}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label>Phone</label>
              <input
                type="tel"
                name="phone"
                value={formDetails.phone}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label>Address</label>
              <input
                type="text"
                name="address"
                value={formDetails.address}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </>
        ) : (
          <>
            <p>
              <strong>Name:</strong> {adminDetails.name}
            </p>
            <p>
              <strong>Email:</strong> {adminDetails.email}
            </p>
            <p>
              <strong>Phone:</strong> {adminDetails.phone}
            </p>
            <p>
              <strong>Address:</strong> {adminDetails.address}
            </p>
          </>
        )}
      </div>

      <div className="action-buttons mt-4">
        {isEditing ? (
          <>
            <button className="btn btn-success me-2" onClick={handleSave}>
              Save
            </button>
            <button className="btn btn-secondary" onClick={() => setIsEditing(false)}>
              Cancel
            </button>
          </>
        ) : (
          <button className="btn btn-primary" onClick={() => setIsEditing(true)}>
            Edit Profile
          </button>
        )}
      </div>
    </div>
  );
};

export default AdminProfile;
