import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Table, Alert } from "react-bootstrap";

const AdminDashboard = () => {
  const [restaurantPartners, setrestaurantPartners] = useState([]);
  const [deliveryBoys, setDeliveryBoys] = useState([]);

  const [message, setMessage] = useState("");
  const navigate = useNavigate();

    // Redirect to login page if no token exists
    useEffect(() => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate("/Adminpage"); // Redirect if token is not found
      }
    }, [navigate]);

   // Fetch restaurant partners
   useEffect(() => {
    const fetchrestaurantPartners = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get("http://localhost:5000/api/restaurant_partner", {
          headers: { Authorization: `Bearer ${token}` }
        });
        setrestaurantPartners(response.data);
      } catch (error) {
        console.error("Error fetching restaurant partners:", error);
        setMessage("Failed to load restaurant partners.");
      }
    };
    fetchrestaurantPartners();
  }, []);

  // Fetch delivery boys
  useEffect(() => {
    const fetchDeliveryBoys = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get("http://localhost:5000/api/delivery_boy", {
          headers: { Authorization: `Bearer ${token}` }
        });
        setDeliveryBoys(response.data);
      } catch (error) {
        console.error("Error fetching delivery boys:", error);
        setMessage("Failed to load delivery boys.");
      }
    };
    fetchDeliveryBoys();
  }, []);


  // Update partner status (Accept/Block)
  const updateRestaurantStatus = async (id, status) => {
    console.log("Updating Status:", { id, status });
    try {
      const response = await axios.patch(
        `http://localhost:5000/api/restaurant_partner/${id}/status`,
        { status }
      );
 
      setMessage(response.data.message);
      setrestaurantPartners((prev) =>
        prev.map((partner) =>
         ( partner.id === id ? { ...partner, status } : partner
        ))
      );
    } catch (error) {
      console.error("Error updating status:", error);
      setMessage("Failed to update status.");
    }
  };

  const updateDeliveryBoyStatus = async (id, status) => {
    try {
      const response = await axios.patch(
        `http://localhost:5000/api/delivery_boy/${id}/status`,
        { status }
      );
      setMessage(response.data.message);
      setDeliveryBoys((prev) =>
        prev.map((boy) => (boy.id === id ? { ...boy, status } : boy))
    
      );
    } catch (error) {
      console.error("Error updating delivery boy status:", error);
      setMessage("Failed to update delivery boy status.");
    }
  };
//  // Logout function
//  const handleLogout = () => {
//   localStorage.removeItem('token'); // Remove token from localStorage
//   navigate("/AdminPage"); // Redirect to login page
// };


  const handleViewProfile = () => {
    navigate("/adminProfile");
  };

  return (
    <div className="admin-dashboard">
      {/* Header Section */}
      <div className="dashboard-header">
        <h1 className="dashboard-heading">Admin Dashboard</h1>
        <Button variant="primary" onClick={handleViewProfile}>
          View Profile
        </Button>
      </div>

      {/* Alert for Messages */}
      {message && <Alert variant="info" className="mt-3">{message}</Alert>}

      {/* Restaurant restaurantPartners Table */}
      <div className="partner-table mt-4">
        <h2>Restaurant Partners</h2>
        {restaurantPartners.length > 0 ? (
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Restaurant Name</th>
                <th>Owner Name</th>
                <th>Email</th>
                <th>Contact Number</th>
                <th>Address</th>
                <th>Cuisine Type</th>
                <th>GST Number</th>
                <th>Food License</th>
                <th>Declaration Accepted</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {restaurantPartners.map((partner) => (
                <tr key={partner.id}>
                  <td>{partner.id}</td>
                  <td>{partner.restaurantName}</td>
                  <td>{partner.ownerName}</td>
                  <td>{partner.email}</td>
                  <td>{partner.contactNumber}</td>
                  <td>{partner.address}</td>
                  <td>{partner.cuisineType}</td>
                  <td>{partner.gstNumber}</td>
                  <td>{partner.foodLicense}</td>
                  <td>{partner.declarationAccepted ? "Yes" : "No"}</td>
                  <td>{partner.status || "Pending"}</td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => updateRestaurantStatus(partner.id, "Active")}
                      disabled={partner.status === "Active"}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => updateRestaurantStatus(partner.id, "Blocked")}
                      disabled={partner.status === "Blocked"}
                    >
                      Block
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => updateRestaurantStatus(partner.id, "Pending")}
                      disabled={partner.status === "Pending"}
                    >
                      Pending
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No restaurant restaurantPartners found.</p>
        )}
      </div>

      {/* Delivery Boy
       Table */}
      <div className="delivery-boys-table mt-4">
        <h2>Deliveryboys </h2>
        {deliveryBoys.length > 0 ? (
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Username</th>
                <th>Email</th>
                <th>Contact Emergency</th>
                <th>Govt Address Proof</th>
                <th>ID Proof</th>
                <th>Address</th>
                <th>status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {deliveryBoys.map((boy) => (
                <tr key={boy.id}>
                  <td>{boy.id}</td>
                  <td>{boy.name}</td>
                  <td>{boy.username}</td>
                  <td>{boy.email}</td>
                  <td>{boy.contact_emergency}</td>
                  <td>
               { boy.govt_address_proof}
                  </td>
                  <td>{boy.id_proof}</td>

                  <td>{boy.address}</td>
                    <td>{boy.status || "Pending" }</td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => updateDeliveryBoyStatus(boy.id, "Active")}
                      disabled={boy.status === "Active"}
                    >
                      Active
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => updateDeliveryBoyStatus(boy.id, "Inactive")}
                      disabled={boy.status === "Inactive"}
                    >
                      Inactive
                    </Button>
                    <Button
                      variant="warning"
                      onClick={() => updateDeliveryBoyStatus(boy.id, "Blocked")}
                      disabled={boy.status === "Blocked"}
                    >
                      Blocked
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => updateDeliveryBoyStatus(boy.id, "Pending")}
                      disabled={boy.status === "Pending"}
                    >
                      Pending
                    </Button>
                  </td>
                </tr>
              ))}
          
      
      </tbody>
    </Table>
  ) : (
    <p>No delivery boys found.</p>
  )
  
  
}

      </div >

      {/* About Section */}
      <div className="about-section mt-4">
        <p>
          Our platform is dedicated to connecting customers with the best local restaurants 
          and ensuring timely delivery with the help of our skilled delivery personnel. 
          We aim to create a seamless dining experience from order to delivery.
        </p>
      </div>
    </div>
  );
};
      
  
    
 

export default AdminDashboard;
