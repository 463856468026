import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../asset/css/RestaurantListPage.css";

const RestaurantListPage = () => {
  const [foodItems, setFoodItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const loggedInPartnerId = localStorage.getItem("restaurant_partner_id"); // Retrieve the stored partner ID

  useEffect(() => {
    const fetchFoodData = async () => {
      const loggedInPartnerId = localStorage.getItem("restaurant_partner_id");
      console.log("Logged In Partner ID:", loggedInPartnerId); // Debugging step
  
      try {
        const response = await axios.get("http://localhost:5000/api/restaurantfood", {
          params: { restaurant_partner_id: loggedInPartnerId }, // Pass partner ID as query parameter
        });

        if (response.data.success) {
          setFoodItems(response.data.data); // Filtered food items
        } else {
          console.error("Failed to fetch food data:", response.data.message);
        }
      } catch (err) {
        console.error("Error fetching food data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchFoodData();
  }, [loggedInPartnerId]);

  if (loading) {
    return <p>Loading food items...</p>;
  }

  return (
    <div>
      <h1>Your Registered Food Items</h1>
      {foodItems.length === 0 ? (
        <p>No food items found. Please register some items.</p>
      ) : (
        <ul>
          {foodItems.map((food) => (
            <li key={food.id}>
              <h3>{food.foodName}</h3>
              <p>Category: {food.category}</p>
              <p>Price: ₹{food.price}</p>
              <p>Discount Price: ₹{food.discountPrice}</p>
              <p>{food.inStock ? "In Stock" : "Out of Stock"}</p>
              <p>Description: {food.description}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default RestaurantListPage;

